.myImage {
    height: 5em;
    width: auto;
    border-radius: 50%;
}

.imageContainer {
    height: 5em;
    width: 5em;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.horizontal {
    width: 10em;
    border-top: 3px solid brown;
    align-self: center;
}

.myJumbotron {
    margin-bottom: 2rem;
    background-color: #e9ecef;
    padding: 100px 0 2rem;
    border-radius: 0;
}

.contentWrapper{
    width: 100vw;
    overflow-x: hidden;
    min-width: 375px;
}

.contentCenter {
    -ms-flex-pack: center;
    justify-content: center;
}

.nameText {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
    text-align: center;
}

.portfolioTitleWrapper {
    margin: 5rem auto;
}

.selfDescriptionText {
    font-size: 1.25rem;
    font-weight: 300;
    text-align: center;
}

.deckContainer1 {
    width: 65vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
}

.deckContainer2 {
    width: 65vw;
    margin: 5rem auto;
}

.portfolioCard {
    margin: 1rem auto;
}

.portfolioCard:Hover {
    border-bottom: 3px solid brown;
}

.portfolioContentWrapper {

}

.portfolioContentWrapper:Hover {
    opacity: 0.5;
}

a.portfolioContentLink {
    color: black;
}

a.portfolioContentLink:Hover {
    color: black;
    text-decoration: none;
}

.backgroundTitleWrapper {
    margin: 7rem auto;
}

.backgroundContainer {
    width: 65vw;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 5rem;
}

.backgroundTableHeader {
    width: 100vw;
    border-bottom: 3px solid #e9ecef;
}

.educationHeader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.educationHeaderText {
    border-bottom: 3px solid brown;
}

.educationSecondSection {
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.educationThirdSection {
    margin-bottom: 5rem;
}

.skillTable {
    border: none!important;
    box-shadow: none!important;
}

.greatingText {
    font-weight: lighter;
    font-size: 30px;
    color: rgba(170, 170, 170, 0.7);
}




