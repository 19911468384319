@import url(https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  scroll-padding-top: 70px;
}

html {
  scroll-padding-top: 70px;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MyNavbar_navWrap__2_VpR {
    font-family:'Press Start 2P', cursive;
    text-transform: uppercase;
}

.MyNavbar_brandFont__2ilFM {
    font-size: 1rem;
}

.MyNavbar_myNavLinksRight__2Ipk2 {
    font-size: 0.8rem;
    color: silver;
    margin: 0 3rem;
}

.MyNavbar_myNavLinksRight__2Ipk2:active{
    color: white;
    border-bottom: 2px solid white;
    text-decoration: none;
}

.MyNavbar_myNavLinksRight__2Ipk2:Hover{
    color: white;
    text-decoration: none;
}

.MyNavbar_myNavLinksLeft__KoeR8 {
    font-size: 0.8rem;
    color: silver;
}

.MyNavbar_myNavLinksLeft__KoeR8:active{
    color: white;
    border-bottom: 2px solid white;
    text-decoration: none;
}

.MyNavbar_myNavLinksLeft__KoeR8:Hover{
    color: white;
    text-decoration: none;
}

.MyNavbar_homeIcon__1DPiZ {
    position: relative;
    display: inline;
    vertical-align: top;
}

.MyNavbar_myNavLinksWrapper__Pq_0Z {
    margin: auto;
}
.Home_myImage__RI56V {
    height: 5em;
    width: auto;
    border-radius: 50%;
}

.Home_imageContainer__Eum0F {
    height: 5em;
    width: 5em;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Home_horizontal__3tIsE {
    width: 10em;
    border-top: 3px solid brown;
    align-self: center;
}

.Home_myJumbotron__2-uzH {
    margin-bottom: 2rem;
    background-color: #e9ecef;
    padding: 100px 0 2rem;
    border-radius: 0;
}

.Home_contentWrapper__2kb65{
    width: 100vw;
    overflow-x: hidden;
    min-width: 375px;
}

.Home_contentCenter__18sl2 {
    justify-content: center;
}

.Home_nameText__2NXG7 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
    text-align: center;
}

.Home_portfolioTitleWrapper__1ghHi {
    margin: 5rem auto;
}

.Home_selfDescriptionText__1Ia3O {
    font-size: 1.25rem;
    font-weight: 300;
    text-align: center;
}

.Home_deckContainer1__3ny8o {
    width: 65vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
}

.Home_deckContainer2__F-Q9K {
    width: 65vw;
    margin: 5rem auto;
}

.Home_portfolioCard__3PO5i {
    margin: 1rem auto;
}

.Home_portfolioCard__3PO5i:Hover {
    border-bottom: 3px solid brown;
}

.Home_portfolioContentWrapper__8_U6H {

}

.Home_portfolioContentWrapper__8_U6H:Hover {
    opacity: 0.5;
}

a.Home_portfolioContentLink__1Fdtg {
    color: black;
}

a.Home_portfolioContentLink__1Fdtg:Hover {
    color: black;
    text-decoration: none;
}

.Home_backgroundTitleWrapper__35p9p {
    margin: 7rem auto;
}

.Home_backgroundContainer__1JXuJ {
    width: 65vw;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 5rem;
}

.Home_backgroundTableHeader__1AKFD {
    width: 100vw;
    border-bottom: 3px solid #e9ecef;
}

.Home_educationHeader__3YhAy {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Home_educationHeaderText__ZAmsa {
    border-bottom: 3px solid brown;
}

.Home_educationSecondSection__jNQmD {
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.Home_educationThirdSection__1plWp {
    margin-bottom: 5rem;
}

.Home_skillTable__1wO3B {
    border: none!important;
    box-shadow: none!important;
}

.Home_greatingText__240FQ {
    font-weight: lighter;
    font-size: 30px;
    color: rgba(170, 170, 170, 0.7);
}





.Project_contentWrapper__2t_b_{
    width: 100vw;
    overflow-x: hidden;
    min-width: 375px;
}

.Project_myJumbotron__1rpc9 {
    margin-bottom: 2rem;
    background-color: #e9ecef;
    padding: 100px 0 2rem;
    border-radius: 0;
}

.Project_selfDescriptionText__2dkOo {
    font-size: 1.25rem;
    font-weight: 300;
    text-align: center;
}

.Project_horizontal__hrBPj {
    width: 10em;
    border-top: 3px solid brown;
    align-self: center;
}

.Project_contentCenter__2FMKR {
    justify-content: center;
}

.Project_nameText__2Rz-K {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
    text-align: center;
}

.Project_videowrapper__1B9_L {
    width: 60vw;
    max-width: 1000px;
    margin: 5rem auto;
}

.Project_videoContainer__1uBMh {
    position: relative;
    margin-top: 5rem;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}

.Project_videoContainer__1uBMh iframe {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.Project_detailTextContainer__1okGL {
    width: 65vw;
    margin: auto;
}

.Project_detailTextContainerForPandaDodge__1CWvL {
    margin-top: 5rem;
    width: 65vw;
    margin-left: auto;
    margin-right: auto;
}

.Project_detailText__3If5K {
    margin: 5rem auto;
    font-size: 1.25rem;
    font-weight: 300;
}

.Project_formulaText__1P0ud {
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 500;
}

.Project_lightBox__1Jozb {
    margin: 5rem auto;
}

.Project_test__2Hp2t {
    width: 46%;
    height: auto;
    margin: 3rem 2%;
}

@media only screen and (max-width: 600px) {
    .Project_videowrapper__1B9_L {
        width: 80vw;
    }

    .Project_test__2Hp2t {
        width: 100%;
        height: auto;
        margin: 1rem 0;
    }

    .Project_nameText__2Rz-K {
        font-size: 2.5rem;
    }

    .Project_detailText__3If5K {
        margin: 3rem auto;
        font-size: 1rem;
        font-weight: 300;
    }

    .Project_formulaText__1P0ud {
        display: flex;
        margin: 1rem auto;
        justify-content: center;
        font-size: 1.0rem;
        font-weight: 500;
    }
}

