.contentWrapper{
    width: 100vw;
    overflow-x: hidden;
    min-width: 375px;
}

.myJumbotron {
    margin-bottom: 2rem;
    background-color: #e9ecef;
    padding: 100px 0 2rem;
    border-radius: 0;
}

.selfDescriptionText {
    font-size: 1.25rem;
    font-weight: 300;
    text-align: center;
}

.horizontal {
    width: 10em;
    border-top: 3px solid brown;
    align-self: center;
}

.contentCenter {
    -ms-flex-pack: center;
    justify-content: center;
}

.nameText {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
    text-align: center;
}

.videowrapper {
    width: 60vw;
    max-width: 1000px;
    margin: 5rem auto;
}

.videoContainer {
    position: relative;
    margin-top: 5rem;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}

.videoContainer iframe {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}

.detailTextContainer {
    width: 65vw;
    margin: auto;
}

.detailTextContainerForPandaDodge {
    margin-top: 5rem;
    width: 65vw;
    margin-left: auto;
    margin-right: auto;
}

.detailText {
    margin: 5rem auto;
    font-size: 1.25rem;
    font-weight: 300;
}

.formulaText {
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 500;
}

.lightBox {
    margin: 5rem auto;
}

.test {
    width: 46%;
    height: auto;
    margin: 3rem 2%;
}

@media only screen and (max-width: 600px) {
    .videowrapper {
        width: 80vw;
    }

    .test {
        width: 100%;
        height: auto;
        margin: 1rem 0;
    }

    .nameText {
        font-size: 2.5rem;
    }

    .detailText {
        margin: 3rem auto;
        font-size: 1rem;
        font-weight: 300;
    }

    .formulaText {
        display: flex;
        margin: 1rem auto;
        justify-content: center;
        font-size: 1.0rem;
        font-weight: 500;
    }
}
