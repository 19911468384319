@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.navWrap {
    font-family:'Press Start 2P', cursive;
    text-transform: uppercase;
}

.brandFont {
    font-size: 1rem;
}

.myNavLinksRight {
    font-size: 0.8rem;
    color: silver;
    margin: 0 3rem;
}

.myNavLinksRight:active{
    color: white;
    border-bottom: 2px solid white;
    text-decoration: none;
}

.myNavLinksRight:Hover{
    color: white;
    text-decoration: none;
}

.myNavLinksLeft {
    font-size: 0.8rem;
    color: silver;
}

.myNavLinksLeft:active{
    color: white;
    border-bottom: 2px solid white;
    text-decoration: none;
}

.myNavLinksLeft:Hover{
    color: white;
    text-decoration: none;
}

.homeIcon {
    position: relative;
    display: inline;
    vertical-align: top;
}

.myNavLinksWrapper {
    margin: auto;
}